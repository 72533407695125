import React from "react";
import { Button } from "./Button";
import styles from "./Cta.module.css";

export type CtaProps = {
  title: string;
  text?: string;
  url: string;
  label: string;
};

export const Cta = ({ title, text, url, label }: CtaProps) => {
  return (
    <div className={styles.cta}>
      {title && <h3 className={styles.title}>{title}</h3>}
      {text && <p className={styles.text}>{text}</p>}
      {label && (
        <Button url={url} variation="primary-diap">
          {label}
        </Button>
      )}
    </div>
  );
};
