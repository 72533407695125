//
// Accepts URL or raw sanity object reference
// <SanityFigure image="https://some.url/image.jpg">
// <SanityFigure image={rawSanityImageData}>
//

import cx from "classnames";
import Image from "next/legacy/image";
import { ComponentProps } from "react";
import styles from "./Figure.module.css";
import { SanityFigure } from "./SanityFigure";

export interface FigureProps extends ComponentProps<"figure"> {
  image: any;
  width?: number;
  height?: number;
  ratio?: number;
  quality?: number;
  caption?: any;
  alt?: string;
  className?: any;
  styled?: boolean;
}

export const Figure = ({
  alt = "",
  image,
  width,
  height,
  ratio,
  caption,
  className,
  styled = true,
  ...rest
}: FigureProps) => {
  if (!image) return null;
  const imageIsObject = typeof image === "object";
  if (imageIsObject && image.image) {
    if (image.caption) caption = image.caption;
    if (image.alt) alt = image.caption;
    if (image.image) image = image.image;
  } else if (imageIsObject) {
    const attrs = SanityFigure(image);
    image = attrs.src;
    if (!width) width = attrs.width;
    if (ratio) height = Math.round(width * ratio);
    if (!height) {
      ratio = attrs.height / attrs.width;
      height = Math.round(width * ratio);
    }
    // if an alt field is available and filled in sanity use that value
    // // if (image.alt) alt = image.alt;
    // return SanityFigure(image);
    // return null;
  }
  // if (typeof image === "string") {
  if (!width) width = 800;
  if (ratio) height = width * ratio;
  if (!height) height = 600;
  if (!image) return null;
  return (
    <figure
      style={{ position: "relative" }}
      className={cx(styles.figure, className)}
      {...rest}
    >
      <div
        className={cx({
          [styles.borderRadius]: styled,
        })}
      >
        <Image
          src={image}
          alt={alt}
          layout="responsive"
          width={width}
          height={height}
          objectFit="cover"
        />
      </div>
      {caption && (
        <figcaption
          className={cx({
            [styles.figCaption]: styled,
          })}
        >
          {caption}
        </figcaption>
      )}
    </figure>
  );
  // }
};
