import React from "react";
import styles from "./StrongLink.module.css";
import cx from "classnames";
import { Icon } from "./Icon";
import { LinkWrapper } from "./linkWrapper";

export type StrongLinkProps = {
  text: string;
  url: string;
  linkType?: "internal" | "external" | "download";
};

export const Underline = ({ className }: { className?: string }) => (
  <svg
    width="37"
    height="1"
    fill="none"
    viewBox="0 0 148 4"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="none"
    className={className}
  >
    <path
      d="M93.72 3.83c-10.638 0-21.266.013-31.904-.014-1.73-.007-3.452-.26-5.192-.348a84.653 84.653 0 0 0-4.361-.116c-17.77-.027-29.511-.04-47.29-.061-1.45 0-2.91-.048-4.361-.11-.219-.013-.533-.224-.603-.388-.053-.136.14-.45.314-.511 1.469-.484 2.946-.894 4.607-.88 2.762.027-.495-.075 2.267-.095 1.32-.007 2.578-.048 3.697-.757.516-.328 1.32-.423 2.019-.505.717-.082 1.451-.027 2.185-.027 15.445.04 30.88.054 46.325.136 3.19.02 6.372.43 9.553.437 26.362.061 44.025.068 70.387.095 1.888 0 3.784.041 5.672.123.324.013.717.3.918.545.227.273-.419.934-.935.975 0 0-1.739.137-2.605.137l-6.589.007c-2.325 0-2.797-.491-4.877.709-.804.463-4.021.818-5.166.818-7.718-.007-15.436-.061-23.162-.089-3.645-.013-7.281 0-10.926 0 .026-.027.026-.054.026-.081Z"
      fill="currentColor"
    />
  </svg>
);

export const StrongLink = ({
  url,
  text,
  linkType = "internal",
}: StrongLinkProps) => {
  return (
    <p className={cx(styles.strongLink, "strongLink")}>
      <LinkWrapper url={url} className={styles.a}>
        <span className={cx(styles.text, styles[linkType])}>
          {text}
          {linkType === "external" && (
            <Icon icon="external" className={styles.icon} />
          )}
          {linkType === "download" && (
            <Icon icon="download" className={styles.icon} />
          )}
        </span>
        <Underline className={styles.underline} />
      </LinkWrapper>
    </p>
  );
};
