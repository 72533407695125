import BlockContent from "@sanity/block-content-to-react";
import cx from "classnames";
import React, { ReactNode } from "react";
import { Cta } from "../components/Cta";
import { Embed } from "../components/Embed";
import { Icon } from "../components/Icon";
import { StrongLink } from "../components/StrongLink";
import { MaxWidth } from "../layouts/MaxWidth";
import { Spacing } from "../layouts/Spacing";
import { Images } from "./Images";
import { Quote } from "./Quote";
import styles from "./RichText.module.css";

export type RichTextProps = {
  children?: string | ReactNode;
  text?: string | ReactNode;
};

const serializers = {
  types: {
    embed: function embed(data: any) {
      return <Embed {...data.node} />;
    },
    strongLink: function strongLink(data: any) {
      return <StrongLink {...data.node} />;
    },
    quote: function quote(data: any) {
      return <Quote {...data.node} />;
    },
    images: function images(data: any) {
      return (
        <Spacing size="md">
          <Images {...data.node} />
        </Spacing>
      );
    },
    cta: function cta(data: any) {
      return (
        <Spacing size="md">
          <MaxWidth size="sm">
            <Cta {...data.node} />
          </MaxWidth>
        </Spacing>
      );
    },
  },
  marks: {
    link: function link({ mark, children }: { mark: any; children: any }) {
      return (
        <>
          <a
            className={cx(
              styles.textlink,
              styles["textlink--" + mark.linkType]
            )}
            target={mark.linkType === "external" ? "_blank" : "_self"}
            rel={mark.linkType === "external" ? "noreferrer" : undefined}
            href={
              mark?.href?.type === "email"
                ? "mailto:" + mark.href.email
                : mark.url
            }
          >
            {children}
            {mark.linkType === "external" && (
              <Icon
                className={styles.textLink__icon}
                icon="external"
                width={12}
                height={12}
              />
            )}
          </a>
        </>
      );
    },
  },
};

/**
 *
 * Richtext can be given a sanity blocks object or hardcoded html elements/string
 *
 * ### Todo:
 *
 * - spacings between components
 *
 *
 */
export const RichText = ({ children, text }: RichTextProps) => {
  if (text) children = text;
  if (typeof children == "string") {
    return <p>{children}</p>;
  }
  // check if children is array of nodes
  if (
    Array.isArray(children) &&
    children.length > 0 &&
    React.isValidElement(children[0])
  ) {
    children = <>{children}</>;
  }
  // if children is valid react node, return
  if (React.isValidElement(children)) {
    return <div className={styles.richText}>{children}</div>;
  }
  // if children is sanity blockcontent, serialize
  if (Array.isArray(children)) {
    return (
      <>
        <BlockContent
          serializers={serializers}
          blocks={children}
          renderContainerOnSingleChild={true}
          className={styles.richText}
        />
      </>
    );
  }
  return <script>{/* unknown */}</script>;
};

export const RichTextContainer = ({ children, text }: RichTextProps) => {
  return (
    <div className={cx(styles.container)}>
      <RichText text={children || text} />
    </div>
  );
};
