import styles from "./Spacing.module.css";
import cx from "classnames";
import { ClassAttributes } from "react";

export type SpacingProps = {
  children: any;
  className?: any;
  size?: "xl" | "lg" | "md" | "sm" | "xs" | "none";
  top?: "xl" | "lg" | "md" | "sm" | "xs" | "none";
  bottom?: "xl" | "lg" | "md" | "sm" | "xs" | "none";
};

export const Spacing = ({
  children,
  size = "lg",
  className,
  top,
  bottom,
}: SpacingProps) => {
  if (size && !top) top = size;
  if (size && !bottom) bottom = size;
  return (
    <div
      className={cx(
        styles.Spacing,
        styles["top-" + top],
        styles["bottom-" + bottom],
        className
      )}
    >
      {children}
    </div>
  );
};
