import cx from "classnames";
import dynamic from 'next/dynamic';
import styles from "./Embed.module.css";
const ReactPlayer = dynamic(() => import("react-player"), { ssr: false }); export type EmbedProps = {
  code?: string;
  url?: string;
  type?: "platform" | "embed";
};

export const Embed = ({ url, code, type = "platform" }: EmbedProps) => {
  return (
    <div className={cx(styles.embed, "embed")}>
      {type === "platform" ? (
        <>{url && <ReactPlayer url={url} />}</>
      ) : (
        <>
          {code && (
            <div id="embed" dangerouslySetInnerHTML={{ __html: code }}></div>
          )}
        </>
      )}
    </div>
  );
};
