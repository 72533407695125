import { useNextSanityImage } from "next-sanity-image";
import { sanityClient } from "../helpers/sanity/server";
// import { Figure } from "./Figure";
export const SanityFigure = (image: string) => {
  const attrs = useNextSanityImage(sanityClient, image);
  return attrs;
  // return <Figure {...attrs} image={attrs.src} />;
};

// export {};

// //
// // Swiss knife for images coming from sainty.
// // <SanityFigure image="https://some.url/image.jpg">
// // <SanityFigure image={rawSanityImageData}>
// //

// import Image from "next/image";
// import React from "react";
// // import styles from "./Figure.module.css";
// // import sanityClient from "sanityClient";
// import { sanityClient } from "../helpers/sanity/server";
// import { useNextSanityImage } from "next-sanity-image";
// import imageUrlBuilder from "@sanity/image-url";
// import cx from "classnames";

// export type SanityFigureProps = {
//   image: any;
//   width?: number;
//   height?: number;
//   ratio?: number;
//   quality?: number;
//   caption?: any;
//   alt?: string;
//   className?: any;
// };

// export const SanityFigure = ({
//   alt = "",
//   image,
//   width,
//   height,
//   ratio,
//   quality = 75,
//   caption,
//   className,
// }: SanityFigureProps) => {
//   // const imageIsObject = typeof image == 'object';
//   const imageAttrs = useNextSanityImage(sanityClient, image);
//   if (!imageAttrs) return <>whep</>;
//   // return <>1{JSON.stringify(image)}2</>;
//   // extract and calculate width & height
//   if (!width) width = imageAttrs.width;
//   if (ratio) height = width * ratio;
//   if (!height)
//     height = Math.round(width * (imageAttrs.height / imageAttrs.width));
//   // if an alt field is available and filled in sanity use that value
//   if (image.alt) alt = image.alt;
//   // build the src url
//   const src = urlFor(image).width(width).height(height).quality(quality).url();
//   if (!src) return null;
//   return (
//     <>
//       <figure
//         style={{ position: "relative" }}
//         className={cx("figure", className)}
//       >
//         <Image
//           src={src}
//           alt={alt}
//           layout="responsive"
//           width={width}
//           height={height}
//           objectFit="cover"
//         />
//         {caption && <figcaption>{caption}</figcaption>}
//       </figure>
//     </>
//   );
// };

// export type UrlFigureProps = {
//   image: string;
//   width?: number;
//   height?: number;
//   ratio?: number;
//   quality?: number;
//   caption?: any;
//   alt?: string;
//   className?: any;
// };
// export const UrlFigure = ({
//   alt = "",
//   image,
//   width,
//   height,
//   ratio,
//   caption,
//   className,
// }: UrlFigureProps) => {
//   if (!width) width = 800;
//   if (ratio) height = width * ratio;
//   if (!height) height = 600;
//   if (!image) return null;
//   return (
//     <>
//       <figure
//         style={{ position: "relative" }}
//         className={cx("figure", className)}
//       >
//         <Image
//           src={image}
//           alt={alt}
//           layout="responsive"
//           width={width}
//           height={height}
//           objectFit="cover"
//         />
//         {caption && <figcaption>{caption}</figcaption>}
//       </figure>
//     </>
//   );
// };

// export const Figure = (props: SanityFigureProps) => {
//   const imageIsObject = typeof props.image == "object";
//   if (imageIsObject) {
//     return SanityFigure(props);
//   } else {
//     return UrlFigure(props);
//   }
// };

// // a sanity function for building urls
// // info: https://www.sanity.io/docs/image-url
// const builder = imageUrlBuilder(sanityClient);
// export function urlFor(source: any) {
//   return builder.image(source);
// }

// // const createImageUrl = ({ src, width, ratio, height = "", dpr = 1 }) => {
// //   const q = dpr === 2 ? 40 : 80;

// //   if (height) {
// //     height = `&h=${height}`;
// //   } else if (ratio) {
// //     height = `&h=${Math.round(width / ratio)}`;
// //   }

// //   return `${src}?auto=format&fit=crop&dpr=${dpr}&fit=fill&q=${q}&w=${width}${height}`;
// // };
// // export const ImageLoader = ({ src, width, quality }) => {
// //   return `${src}?fit=fill&w=${width}&auto=format&q=${quality || 75}`;
// // };
