import React from "react";
import { Figure, FigureProps } from "../components/Figure";
import { MaxWidth, MaxWidthProps } from "../layouts/MaxWidth";
import { Spacing } from "../layouts/Spacing";
import styles from "./Images.module.css";

export type ImagesProps = {
  images: FigureProps[];
  size?: MaxWidthProps["size"];
};

export const Images = ({ images, size = "md" }: ImagesProps) => {
  if (!images) return <></>;
  if (images.length > 1) size = "lg";
  return (
    <Spacing size="sm">
      <MaxWidth size={size}>
        <div className={styles.container}>
          {images.map((item, index) =>
            index === 0 && images.length > 1 ? (
              <Figure
                key={JSON.stringify(item)}
                image={item}
                width={560}
                height={600}
                className={styles.figure}
              />
            ) : (
              <Figure
                key={JSON.stringify(item)}
                image={item}
                width={560}
                height={373}
                className={styles.figure}
              />
            )
          )}
        </div>
      </MaxWidth>
    </Spacing>
  );
};
